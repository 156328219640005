import React from "react";
import { Link, NavLink } from "react-router-dom";
import PrimaryButton from "../../commonElements/buttons/primaryButton.jsx";
import CompanyLogo from "../../../assets/images/MindoxTechno.png"

import "./navBar.css"


const NavigationBar = () => {
    return (

        <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-root-element shadow-sm">
            <div className="container-fluid nav-container">
                <Link to="/" onClick={() => { window.location.href = "/" }} >
                    <img className="d-inline-block align-text-center mx-3" src={CompanyLogo} style={{ width: "7rem", height: "auto" }} alt="Mindox Techno Logo" ></img>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item px-2 dropdown nav-list-item">
                            <button 
                                className="nav-link dropdown-toggle d-flex align-items-center"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ color: '#1d1f48' }}>
                                Products
                                <i className="ms-2 dropdown-arrow bi bi-caret-down-fill"></i>
                            </button>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/platform-solutions" >Platform Solutions</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/coating,-curing-&-cleaing-tool">NewtrOx 3C Tool</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/copper-passivation-coating-tool">ShieldOx Tool</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/automation-solutions">Automation Solutions</NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item px-2 dropdown nav-list-item">
                            <button
                                href="#"
                                className="nav-link dropdown-toggle d-flex align-items-center"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ color: '#1d1f48' }}>
                                Services
                                <i className="ms-2 dropdown-arrow bi bi-caret-down-fill"></i>
                            </button>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/semiconductor-equipment-software-solution">Equipment Software Solutions</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/engineering-resources">MindOx Resource Center</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/manufacutring-sub-contract">Manufacutring Sub-contract</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/available-soon">Legacy Tool Conversion</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/available-soon">Post Warranty Service Packages</NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item px-2 dropdown nav-list-item nav-item">
                            <button
                                href="#"
                                className="nav-link dropdown-toggle d-flex align-items-center"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ color: '#1d1f48' }}>
                                Resources
                                <i className="ms-2 dropdown-arrow bi bi-caret-down-fill"></i>
                            </button>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/press-releases">Press Releases</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/product-launches">Product Launches</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/knowledge-hub">Knowledge Hub</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to="/available-soon">Training Center</NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown px-2 nav-list-item">
                            <button
                                href="#"
                                className="nav-link dropdown-toggle d-flex align-items-center"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ color: '#1d1f48' }}>
                                Life at MindOx
                                <i className="ms-2 dropdown-arrow bi bi-caret-down-fill"></i>
                            </button>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item nav-sub-list-item"><NavLink to={'/about-us'}>About Us</NavLink></li>
                                <li className="dropdown-item nav-sub-list-item"><NavLink to={'/company/careers'}>Careers</NavLink></li>
                            </ul>
                        </li>
                    </ul>
                    <div className="px-2">
                        <PrimaryButton
                            label={'Contact us'}
                            handleOnClick={'/contact-us'} />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavigationBar;